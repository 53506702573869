import React, { useState } from 'react';
import { connect } from 'react-redux';
import Card from '../cards/card';
import { positionSelect } from '../../Redux/Positions/position.selector';
import MyBackDrop from '../Modals/backDrop';
import { ADD_VOTE } from '../../Redux/Positions/position.action';
import {Link, useHistory} from 'react-router-dom'
import './votes.scss';

const Votes = ({ position, match, VOTE, user }) => {
  const post = match.params.collectionId;
  const history = useHistory()
  const [vote, setVote] = useState({ votes: [], open:false, name:'', position:post })
  const [loading, setLoading] = useState(true)
  
  
  console.log(position);
  const myMap = Object.keys(position).map(key=>  {
    return {name:key, votes:Object.values(position)}
  })
   
  return user.currentUser? (  
    <div>
      { vote.open? <div className='center'>
         You already voted
       </div>:
    <div className="votes-container">
      
      <div className="card-container">

      
        {myMap.map((candidate, index) => (
          <Card
            key={candidate.name}
            vote={vote}
            post={post}
            setVote={setVote}
            index={index}
            user={user.currentUser}
            candidateVotes={candidate.votes}
            name={candidate.name}
            image={candidate.image}
          />
        ))}
      </div>
      <Link className="centre" to={`/election/senate/${user.currentUser.level}`}>Done with executives? Click to vote for senate</Link>
        
      <div className="centre">
        <button
          className="btn-submit draw-border"
          onClick={() => {
            VOTE(vote);
           history.push("/election/executives")
          
          }}
        >
          Submit
        </button>
      </div>
     
    </div>
}
    </div>
  ) : (
    <div className="loading">
      <MyBackDrop />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  position: positionSelect(ownProps.match.params.collectionId)(state),
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  VOTE: (vote) => dispatch(ADD_VOTE(vote)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Votes);