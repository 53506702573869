import React from 'react'
import './Jumbo.scss'
import Jumbotron from 'react-bootstrap/Jumbotron'
export default function Jumbo({titleText, height,paragraph, background}) {
    
    return (
        <div>
              <Jumbotron style={{backgroundImage:`url(${background})`, height:height}}>
  
  <h1 className='jumbo-title'>{titleText}</h1>
  <p className='jumbo-text'>{paragraph}</p>

</Jumbotron> 
        </div>
    )
}
