import React from 'react'

export const Footer = () => {
    return (
        
        <div class="footer">

<a href="#" class="back-to-top"><i class="fa fa-chevron-up"></i></a>
        <div class="container">
            <div class="row">
                <div class="col-lg-7">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="footer-contact">
                                <h2>Our Address</h2>
                                <p><i class="fas fa-map-marker-alt"></i>Block G Room GG 25, Alexander Brown Hall, University College Hospital Ibadan.</p>
                                <p><i class="fas fa-phone-alt"></i>+2347014260110</p>
                                <p><i class="fas fa-envelope"></i>uadsibadan@gmail.com</p>
                                <div class="footer-social">
                                    <a href="https://twitter.com/ibadandentist"><i class="fas fa-twitter"></i></a>
                                    <a href="https://twitter.com/officialuads"><i class="fas fa-instagram"></i></a>
                                    <a href="https://www.linkedin.com/company/unibadan-association-of-dental-students/"><i class="fas fa-linkedin-in"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="footer-link">
                                <h2>Quick Links</h2>
                                <a href="/contact">Contact Us</a>
                                <a href="/about">About</a>
                                <a href="/resources">Resources</a>
                                <a href="/gallery">gallery</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container copyright">
            <div class="row">
                <div class="col-md-6">
                    <p>&copy; <a href="#">UADS</a>, All Right Reserved.</p>
                </div>
                
            </div>
        </div>
    </div>
    
    )
}
