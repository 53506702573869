import {createStore, applyMiddleware} from 'redux'
import logger from 'redux-logger'
import rootreducer from './rootReducer'
import thunk from 'redux-thunk'
import { persistStore} from 'redux-persist'



const middlewares = [thunk] 
if(process.env.NODE_ENV ==='development'){ middlewares.push(logger)}

export const store = createStore(rootreducer, applyMiddleware(...middlewares))

export const persistor = persistStore(store)
export default {store, persistor} 