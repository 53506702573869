import React, {useState} from 'react'
import {useLocation} from 'react-router-dom'
import "./layout.scss"
const links = ['Home', 'About','Gallery', 'Election', 'Team',]
function Navbar() {
 
  let location = useLocation();
 
  const path = location.pathname.substring(1)
  
  

    return (
    <section> 
<div className="top-bar d-none d-md-block">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-8">
                        <div className="top-bar-left">
                            <div className="text">
                                <i className="far fa-clock"></i>
                                <h2>COVID-19:Use Your</h2>
                                <p>Nosemasks</p>
                            </div>
                            <div className="text">
                                <h2>Wash</h2>
                                <p>Your Hands!</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="top-bar-right">
                            <div className="social">
                                <a href="https://twitter.com/ibadandentistry"><i className="fab fa-twitter"></i></a>
                                <a href=""><i className="fab fa-facebook-f"></i></a>
                                <a href=""><i className="fab fa-linkedin-in"></i></a>
                                <a href="https://instagram.com/officialuads"><i className="fab fa-instagram"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div className="navbar navbar-expand-lg bg-dark navbar-dark">
            <div className="container-fluid">
            <div id="logo" className="pull-left">
        <a href="/"><img src="UADS_logo.svg"   alt="" title="" /></a>
        
      </div>
                <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                    <div className="navbar-nav ml-auto">
                    <a href="/home" className={`nav-item nav-link ${path==='home'? 'active': null}`}>Home</a>
                        <a href="/about" className={`nav-item nav-link ${path==='about'? 'active': null}`}>About</a>
                        <a href="/team" className={`nav-item nav-link ${path==='team'? 'active': null}`}>Team</a>
                        <a href="/payments"  className={`nav-item nav-link ${path==='payments'? 'active': null}`}>Pay Dues</a>
                        <a href="/login"  className={`nav-item nav-link ${path==='login'? 'active': null}`}>Profile</a>
                        <div className="nav-item dropdown">
                            <a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown">Pages</a>
                            <div className="dropdown-menu">
                                <a href="https://dentatics.ibadandentistry.org" className="dropdown-item">Dentatics</a>
                                <a href="https://codeh.ibadandentistry.org" className="dropdown-item">Codeh</a>
                            </div>
                        </div>
                        <a href="/contact"  className={`nav-item nav-link ${path==='contact'? 'active' : null}`}>Contact Us</a>
                    </div>
                </div>
            </div>
        </div>
        </section> 
    )
}


export default Navbar
