import React from 'react';
import {createStructuredSelector} from 'reselect'
import {loading, selectCurrentUser} from '../../Redux/User/user.Selector'
import Profile from '../../components/Profile/profile'
import {connect} from 'react-redux'
import MyBackdrop from '../../components/Modals/backDrop';
import SignIn from '../../components/sign-in/sign-in';



 function SignInPage({user, fetching, match}) {


  return fetching?(<div className='loading'><MyBackdrop /></div>)
  : user?(<Profile match={match} />)
  : <SignIn />
}


const mapStatetoProps = createStructuredSelector({
	user:selectCurrentUser,
  fetching: loading
	
  })

export default connect(mapStatetoProps)(SignInPage);