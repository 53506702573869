import React from 'react'
import './team.scss'
function Team({name, position, facebook, twitter, img, linkedin, instagram}) {
    return (
        <div class="col-lg-3 col-md-6">
                        <div class="team-item">
                            <div class="team-img">
                                <img src={img} alt="Team Image" />
                            </div>
                            <div class="team-text">
                                <h2>{name}</h2>
                                <p>{position}</p>
                                <div class="team-social">
                                    <a href={twitter}><i class="fab fa-twitter"></i></a>
                                    <a href={facebook}><i class="fab fa-facebook"></i></a>
                                    <a href={linkedin}><i class="fab fa-linkedin-in"></i></a>
                                    <a href={instagram}><i class="fab fa-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
    )
}

export default Team
