import React, { useState, useEffect } from 'react'
import { parse } from 'node-html-parser';
import OwlCarousel from 'react-owl-carousel'


const Blog = () => {
    const [state, setState] = useState([]);

const textParser = (content)=>{
let root = parse(content)
   return(root.text.substring(0, 201)+ '...')
}


const imgParser = (content)=>{
    let root = parse(content)
       return(root.querySelector('img').attrs.src)
    }



useEffect(()=>{
   let isMounted = true;
   

    fetch('https://www.googleapis.com/blogger/v3/blogs/5868687098797974222/posts?key=AIzaSyArDKDbwCVqk3L3_werV-T0Z1uyfzgv2dY')
    .then((data) => data.json())
    .then((data)=> data.items.slice(0, 7).map(post=>{
        const date = post.published;
        const url = post.url;
        const  title = post.title;
        const labels = post.labels
        const text = textParser(post.content)
        const img = imgParser(post.content)
        return { date, text, url, title, img,  labels}
    }))
    .then((data) =>  {
        console.log(data)
         return isMounted? setState(data): null});
        
        return ()=>{ isMounted = false}
},[state.length])

console.log(state)
    return(
        <div className="blog">
        <div className="container">
            <div className="section-header">
                <p>Dentatics Press</p>
                <h2>Latest From Our Press</h2>
            </div>
        <OwlCarousel  navElement={'div'} navText = {[
            '<i class="fa fa-angle-left" aria-hidden="true"></i>',
            '<i class="fa fa-angle-right" aria-hidden="true"></i>'
        ]} autoplay={true} responsive={{
            0:{
                items:1
            },
            576:{
                items:1
            },
            768:{
                items:2
            }
        }} loop  nav={true} lazyLoad={true} dots={false} className="owl-carousel blog-carousel">

            {state.length > 0? state.map(post=>{
                return (<div key={post.title} className="blog-item">
                <div className="blog-img">
                    <img src={post.img} alt="Blog" />
                </div>
                <div className="blog-content">
                    <h2 className="blog-title">{post.title}</h2>
                    <div className="blog-meta">
                        <i className="fa fa-list-alt"></i>
                        <a>{post.labels? post.labels[0] : null}</a>
                        <i className="fa fa-calendar-alt"></i>
                        <p>{post.date}</p>
                    </div>
                    <div className="blog-text">
                        <p>
                            {post.text} </p>
                        <a className="btn" href={post.url}>Read More</a>
                    </div>
                </div>
            </div>)
            }) : <div>Loading...</div>}
                    
                  
                </OwlCarousel>

                </div>
                </div>
    )
}



export default Blog