import {UserActionTypes} from  './user.Types'
import {firestore} from '../../Firebase/Utility'
import { convertUsersSnapShotToMap} from '../../Firebase/Utility'
export const setCurrentUser = user => ({
    type: UserActionTypes.SET_CURRENT_USER,
    payload: user
})

export const fetchCollectionStart = ()=>({
    type:UserActionTypes.FETCH_COLLECTION_START,
   
  
})

export const fetchCollectionSuccess = (collections) => ({
    type:UserActionTypes.FETCH_COLLECTION_SUCCESS,
    //payload:collections
})
export const fetchCollectionFailure = (error) => ({
    type:UserActionTypes.FETCH_COLLECTION_FAILURE,
    payload:error
})


export const fetchUsersStartAsync = ()=>  {
    return dispatch => {
         const userRef = firestore.collection('users')
         dispatch(fetchCollectionStart())
         userRef.get().then(snapshot =>{
            const collections = convertUsersSnapShotToMap(snapshot, 'users');
          dispatch(fetchCollectionSuccess(collections))  
         }).catch(error => dispatch(fetchCollectionFailure(error.Message)))

  }
}