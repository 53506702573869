import { SettingsInputAntennaTwoTone } from '@material-ui/icons'
import React, {useState, useEffect} from 'react'
import VoteCheckModal from '../Modals/voteCheckModal'

import './card.scss'
 const Card = ({image, post,  candidateVotes, candidate, setSaveVote,  user, name,index, setVote, vote,}) => {

  const {fullName, matricNo} = user 
  console.log(candidateVotes[0])
   console.log(matricNo)
   const [voteModal, setVoteModal] = useState(false)
   const [openModal, setOpenModal] = useState(true)
   const [voted, setVoted] = useState(false)
   const [state, setState] = useState(false)

   useEffect(()=>{
handleVoted()
        
  })



 const onClose = () => {
    setOpenModal(true);
  };

 
  const handleClose = () => {
    setOpenModal(false)
  };

  function handleVoted (){
    let open = candidateVotes[0].votes.some(voter=>voter.matricNo==matricNo)
    setState(open)
  }
 
  let open = candidateVotes[0].votes.some(voter=>voter.matricNo==matricNo)
   const handleVote = (myVote)=>{
     setVote({
       votes:{matricNo:matricNo, vote:myVote, },
       open:open,
       position:post,
       name:name
     })

    setVoted(true)
     
     
    
   }
   console.log(candidate)
    return (
       !state&&<div >
           <div className='card'>
    <img src='https://mpng.subpng.com/20180523/tha/kisspng-businessperson-computer-icons-avatar-clip-art-lattice-5b0508dc6a3a10.0013931115270566044351.jpg' alt="Candidate" className="card__image" />
    <p className="card__name">{name}</p>
    

      <div className="post">
        <p >{post}</p>  
      </div>

      

    
   
    <button onClick={()=>{handleVote('Yes')}} className="btn draw-border">Vote Yes</button>
    <button onClick={()=>handleVote('No')} className="btn draw-border">Vote No</button>
    
  </div> 
  {voteModal?<VoteCheckModal open={openModal} onClose={onClose} handleClose={handleClose}/>:null}
        </div>
        
    )

}


export default Card
