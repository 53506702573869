import {combineReducers} from 'redux'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import positionReducer from './Positions/positions.reducer'
import userReducer from './User/user.Reducer'



const persistConfig  = {
    key: 'root',
    storage,
    whitelist: ['position']
}

const rootReducer = combineReducers({
    positions:positionReducer,
    user:userReducer
   })


export default persistReducer(persistConfig, rootReducer)