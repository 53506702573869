import React, {useEffect} from 'react' 
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'
import {fetchUsersStartAsync, setCurrentUser} from './Redux/User/user.Actions'
import { selectCurrentUser } from './Redux/User/user.Selector'
import './App.scss';
import {auth, firestore} from './Firebase/Utility'
import { Footer } from './components/layout/footer';
import {  Switch,BrowserRouter, Route} from 'react-router-dom'
import { Home } from './Pages/Home/Home';
import {About} from './Pages/About/About';
import {Gallery} from './Pages/Gallery/Gallery'
import {Resources} from './Pages/Resources/Resources'
import Result from './Pages/Results/results'
import {ContactPage} from './Pages/Contacts-us/Contact-us'
import TeamPage  from './Pages/Team/Team'
import {Programmes} from './Pages/Programmes/Programmes'
import Navbar from './components/layout/navbar'
import SignUp from './components/Sign-up/sign-Up'
import { fetchPositionsStartAsync } from './Redux/Positions/position.action'
import Memberlist from './Pages/List/list'
import Payments from './Pages/Payment/payments'
import SigninPage from './Pages/Sign-up-and-sign-in/signin'

function App({setCurrentUser, fetchUsers, fetchPositions, currentUser}) {
  let unsubscribefromAuth = null

 
useEffect(()=>{
		unsubscribefromAuth = auth.onAuthStateChanged(async (userAuth) => {
			if (userAuth) {
				const userRef =  await  firestore.doc(`users/${userAuth.displayName}`) 

				userRef.onSnapshot(snapShot => {
					setCurrentUser({
						
							id: snapShot.id,
							...snapShot.data()
						
          });
          
        });
        
			}  
		setCurrentUser(userAuth)
		
  })

  return ()=> {
    unsubscribefromAuth() 
  }
}, [])

// useEffect(() => {
//   fetchPositions()

 
// }, [])

// useEffect(() => {
//    fetchUsers()
 
// }, [])

const DefaultRoutes =()=>{
  return(
<div>
<Navbar />  
     <Switch>
     <Route  exact path ={["/Home", '/']} component ={Home}/>
     <Route exact path ='/about'  component ={About}/>
     <Route exact path ='/causes'  component ={Programmes}/>
     <Route exact path ='/gallery'  component ={Gallery}/>
     <Route exact path ='/resources'  component ={Resources}/>
     <Route   path ='/payments' component ={Payments} />
     <Route path='/contact' component ={ContactPage} />
    <Route path='/team' component={TeamPage} />
    <Route path='/signup' component ={SignUp} />
      </Switch>
      <Footer /> 
        
</div>
  )
}
 return  (
    <div className="App">
       <BrowserRouter >
       <Switch>
      
       <Route   path ='/login' component ={SigninPage} /> 
       <Route exact path ='/members' component ={Memberlist} /> 
        <Route path='/results' component ={Result} />
         <Route component={DefaultRoutes} />
         
       </Switch>
       </BrowserRouter>
    </div>
  )

}

const mapStatetoProps = createStructuredSelector({
	currentUser:selectCurrentUser,
  
	
  })

 const mapDispatchToProps = dispatch =>({
	 setCurrentUser: user => dispatch(setCurrentUser(user)),
   //fetchUsers:()=> dispatch(fetchUsersStartAsync()),
   //fetchPositions:()=>dispatch(fetchPositionsStartAsync())
 })

export default connect(mapStatetoProps, mapDispatchToProps)(App);