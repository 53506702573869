import React from 'react'
import Blog from '../../components/Carousel/blog'
import Main from '../../components/Carousel/Main'
import About from '../../components/homeAbout/about'
import VideoModal from '../../components/Modals/videoModal'
import "./Home.scss"

export const Home = () => {
    return (
      <div>
    
  <Main />
  <VideoModal />
  <About />       
  <Blog />
      
        </div>
        


     
    )
}
