import {createSelector} from 'reselect'
import memoize from 'lodash.memoize'    

const selectPosition = state => state.positions
export  const selectExecutive = createSelector([selectPosition], position=>position.executive)
export  const selectLegislative = createSelector([selectPosition], position=>position.legislative)
export const President = createSelector([selectExecutive],  position=> position["President"])
export const vicePresident = createSelector([selectExecutive],  position=> position["Vice President"])
export const GenSec = createSelector([selectExecutive],  position=> position['General Secretary'])
export const SDO = createSelector([selectExecutive],  position=> position["Special Duties Officer"])
export const AGS = createSelector([selectExecutive],  position=> position["Assistant General Secretary"])
export const treasurer = createSelector([selectExecutive],  position=> position['Treasurer'])
export const sportSecretary = createSelector([selectExecutive],  position=> position["Sports Secretary"])
export const  CODEH = createSelector([selectExecutive],  position=> position["CODEH Chairman"])
 

export const selectExco = createSelector([selectExecutive], position =>position? Object.keys(position).map(key=> {
	let  myobj ={}
	const object = position[key]
	const newObjectkey = Object.keys(object)
	const values = Object.values(object)
    newObjectkey.map(obj=> {
		myobj = {
			name:obj,
			votes:values[0].votes,
			position:values[0].position
		}

		return myobj
	})

	return myobj
}):[])

export const selectLegis = createSelector([selectLegislative], position =>position? Object.keys(position).map(key=> {
return {
	class:key,
	candidates: Object.values(position[key])
}
})
:[])
export const positionSelect = memoize((collectionUrlParam) =>
	createSelector([ selectExecutive ], (positions) => positions?
		positions[collectionUrlParam] : null
	));
  

	export const SenateSelect = memoize((level) =>
	createSelector([ selectLegislative ], (positions) => positions?
		positions[level] : null
	));
 

	export const selectClass =createSelector([selectExco], positions=> positions? (Object.values(positions)).map(key=>{
		let arr = []
		const mykey = key
		mykey.votes.map(vote=>{
			arr.push(vote)
		})
       


	return {
		name:key.name,
		voters:arr
	}
	}): [])