import React from 'react'

function About() {
    return (
        <div className="about">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="about-img">
                            <div className="about-img-1">
                                <img src="https://firebasestorage.googleapis.com/v0/b/uads-2b6ac.appspot.com/o/resources%2Fimages%2Ftwo.jpeg?alt=media&token=7c12f48d-8ce1-4156-9584-3ac8a4c9d113" alt="Image" />
                            </div>
                            <div className="about-img-2">
                                <img src="https://firebasestorage.googleapis.com/v0/b/uads-2b6ac.appspot.com/o/resources%2Fimages%2FEsther.jpeg?alt=media&token=b40606e7-85b9-4052-b760-87da9d652fc5" alt="Image" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="section-header">
                            <p>Learn About Us</p>
                            <h2>Who We Are</h2>
                        </div>
                        <div className="about-text">
                            <p>
                            The Unibadan Association of Dental Students, UADS represents all students studying Dentistry and Dental Surgery in the University of Ibadan.   </p>
                            <p>
                            The Unibadan Association of Dental Students was founded in 1978, few years after the faculty of Dentistry was created in University of Ibadan. We represent the best interest of the student body and also work...</p>
                            <a className="btn" href="/about">Learn More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default About
