import {UserActionTypes} from './user.Types'
const INITIAL_STATE = {
    currentUser: null,
    //users:null,
    errorMessage:undefined,
    isFetching : false
}

const userReducer = (state =INITIAL_STATE, action) => {
switch(action.type){
    
    case UserActionTypes.SET_CURRENT_USER:
        return{
            ...state,
            currentUser:action.payload
        } 
        case UserActionTypes.FETCH_COLLECTION_START:
           return{
               ...state,
               isFetching: true
           }
           
           case UserActionTypes.FETCH_COLLECTION_SUCCESS:
               return{
                   ...state,
                   isFetching: false,
                   users: action.payload

               }
            
               case UserActionTypes.FETCH_COLLECTION_FAILURE:
                   return{
                    ...state,
                       isFetching: false,
                       errorMessage: action.payload,
                       
                   }  
        default:
            return state
}
}
export default userReducer