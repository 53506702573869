import firebase from 'firebase/app'
import  'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'


const config = {
        apiKey:process.env.REACT_APP_APIKEY,
        authDomain:process.env.REACT_APP_AUTHDOMAIN,
        projectId:process.env.REACT_APP_PROJECTID,
        storageBucket:process.env.REACT_APP_STORAGEBUCKET,
        messagingSenderId:process.env.REACT_APP_MESSAGINGSENDERID,
        appId:process.env.REACT_APP_APPID,
        measurementId:process.env.REACT_APP_MEASUREMENTID
}


export const CreateUserProfileDocument = async (user, additionalData) => {
        if (!user) return;
        let userRef;


        if(additionalData===undefined){
         return userRef = firestore.doc(`users/${user.displayName}`)   

        }else{
                userRef = firestore.doc(`users/${additionalData.matricNo}`)
        }
        


        const Snapshot = await userRef.get()

        if(!Snapshot.exists){
            const CreatedAt = new Date()
            const {fullName, email, url, matricNo, level } = user

            try {
                    await userRef.set({
                            fullName,
                            email,
                            matricNo,
                            level,
                            url,
                            CreatedAt,
                            ...additionalData

                    })
                    
            } catch (error) {
                    console.log('error creating user', error.message)
                    
            }
         


        }else {


                await user.delete()
                throw new Error('This Matric Number has already been registered')
        }
               
        
       


        
        return userRef
}


export const convertUsersSnapShotToMap =(collections) => {
  let myList = {}
       const transFormedCollection =  collections.docs.map(doc => {
        
                const {matricNo, fullName, level} = doc.data()
                 
               return myList[matricNo]={matricNo, level}
                 
         })
         return myList
    }


    export const convertPositionsSnapShotToMap =(collections) => {
        let myList = []
             const transFormedCollection =  collections.docs.map(doc => {
              
                      
                       
                      myList.push(doc.data())
                       
                       
               })
               return myList
          }





firebase.initializeApp(config)
export const auth = firebase.auth()
export const storage = firebase.storage()
export const firestore = firebase.firestore()
 const google = new firebase.auth.GoogleAuthProvider()
 google.setCustomParameters({prompt: 'select_account'})
export const SighInWithGoogle = () => auth.signInWithPopup(google)