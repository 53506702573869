import React from 'react'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'
import {Redirect}  from 'react-router-dom'
import {selectCurrentUser} from '../../Redux/User/user.Selector'

function SenateHome({user}) {
    return (
        <div className='voting-directory'>
            <h3 className='head-text'>  Click the link below to vote</h3>
            <div className='directory'>
            <div className='item'>
                <h5>
                   <a href={`/election/senate/${user.level}`}>Vote Now</a> 
                </h5>
                </div>
            </div>
        </div>
    )
}

const mapStatetoProps = createStructuredSelector({
	user:selectCurrentUser,
	
  })


export default connect(mapStatetoProps)(SenateHome)
