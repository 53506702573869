import React from 'react';
import {auth} from '../../Firebase/Utility'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AppMenu from './Menu'
import {connect} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {createStructuredSelector} from 'reselect'
import {selectCurrentUser} from '../../Redux/User/user.Selector'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

 function MyAppBar({currentUser}) {
  const classes = useStyles();
  
 const history = useHistory()
  const login = ()=>{
      history.push('/election')
  }


const logout = async ()=>{
    await auth.signOut()
}
  return (
    <div className={classes.root}>
      <AppBar color='default'position="static">
        <Toolbar>
          <AppMenu />
          <Typography variant="h6" className={classes.title}>
            Home
          </Typography>
          {currentUser?
          <Button onClick={logout} color='inherit'>Logout</Button>
          : <Button onClick={login} color='inherit'>Login</Button>
          }
          
        </Toolbar>
      </AppBar>
    </div>
  );
}


const mapStatetoProps = createStructuredSelector({
	currentUser:selectCurrentUser,
	
  })


  export default connect(mapStatetoProps)(MyAppBar)