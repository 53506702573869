import React from 'react'
import Jumbo from '../../components/Jumbotron/Jumbotron'
export const Programmes = () => {
    return (
        <div>
             <Jumbo titleText='Gallery' background='/public/m_UADS_b.png' paragraph='Welcome To Our Gallery' />
        
        </div>
    )
}
