import React,{useState} from 'react' 
import emailjs from 'emailjs-com';

import './contact.scss'
const Contact = () => {  
    const [response, setResponse] = useState('')

  const sendEmail =(e)=> {
        e.preventDefault();
        const USER_ID = 'user_cBAMFUH0YdwQscLQz81ij'
        const Service_ID = 'service_gj63gnh'
        const Template_ID = 'template_wisA9rl5'
        emailjs.sendForm(Service_ID, Template_ID, e.target, USER_ID)
          .then((result) => {
    
    setResponse(result)
    document.getElementById("contactForm").reset()

               
          }, (error) => {
              console.log(error.text);
          });
      }
    return (
        <div className="contact">
            <div className="container">
                <div className="section-header">
                    <p>Get In Touch</p>
                    <h2>Get In Touch For Any Questions</h2>
                </div>
                <div className="row align-items-center">
                    <div className="col-md-5">
                        <div className="contact-info">
                            <div className="contact-icon">
                                <i className="fas fa-map-marker-alt"></i>
                            </div>
                            <div className="contact-text">
                                <h3>Our Secretariat</h3>
                                <p>Block G Room GG 25, Alexander Brown Hall, University College Hospital Ibadan.</p>
                            </div>
                        </div>
                        <div className="contact-info">
                            <div className="contact-icon">
                                <i className="fas fa-phone-alt"></i>
                            </div>
                            <div className="contact-text">
                                <h3>Call for Help</h3>
                                <p>+2347014260110</p>
                            </div>
                        </div>
                        <div className="contact-info">
                            <div className="contact-icon">
                                <i className="fa fa-envelope"></i>
                            </div>
                            <div className="contact-text">
                                <h3>Email for Information</h3>
                                <p>uadsibadan@gmail.com</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className="contact-form">
                            <div id="success"></div>
                            <form name="sentMessage" id="contactForm" onSubmit={sendEmail} novalidate="novalidate">
                                <div className="control-group">
                                    <input type="text" className="form-control" id="name" placeholder="Your Name" name='name' required="required" data-validation-required-message="Please enter your name" />
                                    <p className="help-block text-danger"></p>
                                </div>
                                <div className="control-group">
                                    <input type="email" className="form-control" id="email" placeholder="Your Email" name='email'required="required" data-validation-required-message="Please enter your email" />
                                    <p className="help-block text-danger"></p>
                                </div>
                                <div className="control-group">
                                    <input type="text" className="form-control" id="subject" placeholder="Subject" name='subject' required="required" data-validation-required-message="Please enter a subject" />
                                    <p className="help-block text-danger"></p>
                                </div>
                                <div className="control-group">
                                    <textarea className="form-control" id="message" placeholder="Message" name='message' required="required" data-validation-required-message="Please enter your message"></textarea>
                                    <p className="help-block text-danger"></p>
                                </div>
                                <div>
                                    <button className="btn" type="submit" id="sendMessageButton">Send Message</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact
