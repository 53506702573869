import React from 'react';
import OwlCarousel from 'react-owl-carousel';

const  Carousel = [
	{
		title: 'Ibadan Dentistry',
		img: 'https://firebasestorage.googleapis.com/v0/b/uads-2b6ac.appspot.com/o/resources%2Fimages%2Fgroup.jpeg?alt=media&token=a72957d6-0f2d-402a-9398-cc5d2d339afb',
        btn:'About Us',
        link:'/about',
		para: 'Welcome To The Official website of the Unibadan Association of Dental Students'
	},
	{title:'Pay Your Dues',
img:'https://firebasestorage.googleapis.com/v0/b/uads-2b6ac.appspot.com/o/resources%2Fimages%2F2k16.jpeg?alt=media&token=e53c777f-6599-44e4-a3aa-010f22742d31',
btn:'Pay Dues',
link:'/payments',
para:'You Can Now Pay Dues Online! Click Below To Pay Dues!'},
	
];
function Main() {
	return (
		<div>
			<div className="carousel">
				<div className="container-fluid">
					<OwlCarousel
						navElement={'div'}
						navText={[
							'<i class="fa fa-angle-left" aria-hidden="true"></i>',
							'<i class="fa fa-angle-right" aria-hidden="true"></i>'
						]}
						items={1}
						loop
						autoplay
						nav
						dots={false}
						lazyLoad={true}
						smartSpeed={300}
						animateOut="fadeOut"
						animateIn="fadeIn"
						className="owl-carousel"
					>


                       {Carousel.map((item)=>{

                           return <div className="carousel-item">
                           <div className="carousel-img">
                               <img src={item.img} alt="Image" />
                           </div>
                           <div className="carousel-text">
                               <h1>{item.title}</h1>
                               <p>{item.para}</p><div className="carousel-btn">
                                   <a className="btn" href={item.link}>
                                       <i className="fa fa-link" />{item.btn}
                                   </a>
                                   
                               </div>
                           </div>
                       </div>

                       })} 
						
						
					</OwlCarousel>
				</div>
			</div>
		</div>
	);
}

export default Main;
