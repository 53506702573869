import React from 'react'
import './About.scss'
import Jumbo from '../../components/Jumbotron/Jumbotron.js'


export const About = () => {
    return (
        <div>

         <Jumbo titleText='About Uads' paragraph='Learn More About Us' background='https://firebasestorage.googleapis.com/v0/b/uads-2b6ac.appspot.com/o/resources%2Fimages%2Fgroup.jpeg?alt=media&token=a72957d6-0f2d-402a-9398-cc5d2d339afb' />   
         <section className='about-tex'>
             <div className='bold-text'>
            <p>The Unibadan Association of Dental Students, UADS represents all students studying Dentistry and Dental Surgery in the University of Ibadan.
The Unibadan Association of Dental Students was founded in 1978, few years after the faculty of Dentistry was created in University of Ibadan. We represent the best interest of the student body and also work hard towards fostering a sense of unity and belonging among our members.
</p>
        </div>
        <div>
         <h3>Our Vision</h3>
        <p>Our mission is to always put the members' interest first in all affairs and foster a strong relationship bond among our members, even after graduation.
To have an alumni body that is strong and accomplished.
</p>
        </div>
        <h3>Our  Mision</h3>
        <p>We strive to have an association with interested members who have a sense of pride in themselves and the association at large. To create several opportunities for our members and give back to the society as much as possible.</p>
        </section>
        </div>
    )
}
