import PositionTypes from './position.types'
import {ADD_VOTE, SENATE_VOTE} from './positions.utils'
const INITIAL_STATE = {
   executive:{},
   legislative:{},
   isFetching : false
}

const positionReducer = (state=INITIAL_STATE, action) => {
    const payload = action.payload
    if(payload){
         switch (action.type) {
           
                case PositionTypes.FETCH_POSITION_START:
                   return{
                       ...state,
                       isFetching: true
                   }
                   
                   case PositionTypes.FETCH_POSITION_SUCCESS:
                       return{
                           ...state,
                           isFetching: false,
                           executive: action.payload[0],
                           legislative:action.payload[1]
        
                       }
                       case PositionTypes.SENATE_VOTE:
                            SENATE_VOTE(action.payload)
                    
                       case PositionTypes.FETCH_POSITION_FAILURE:
                           return{
                            ...state,
                               isFetching: false,
                               errorMessage: action.payload,
                               
                           }  
                           case PositionTypes.ADD_VOTE:
                            ADD_VOTE(action.payload)
                        
                     default:
                return state;
         }

        
    }
   return state
}
export default positionReducer