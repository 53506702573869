import React, {useState,useRef,  useEffect} from 'react'
import { connect } from 'react-redux'
import Card from '../cards/card';
import { SenateSelect } from '../../Redux/Positions/position.selector';
import MyBackDrop from '../Modals/backDrop';
import { SENATE_VOTE } from '../../Redux/Positions/position.action';
import {Link, useHistory} from 'react-router-dom'

const SenateVotes = ({ position, match, VOTE, user }) => {
    const post = match.params.level;
    const [vote, setVote] = useState({ votes: [], open:false, name:'', position:post });
  
    const cardrefs = useRef(new Array())
  

   



    console.log(position );
     const myMap = position? Object.keys(position).map(key=>  {
      return {name:key, votes:Object.values([position[key]])}
    }):[]
     const {currentUser} = user
     const {level} = currentUser
     console.log(myMap)
    return user.currentUser? (  
      <div>
        { level !== post? <div className='center'>
              You are not a member of this Class!!
         </div>:
      <div className="votes-container">
        
        <div className="card-container">
  
       
          {myMap?.map((candidate, index) => (
            <Card
              key={candidate.name}
              vote={vote}
              post={post}
              candidate={candidate}
              setVote={setVote}
              index={index}
              ref ={element=> cardrefs.current.push(element)}
              user={user.currentUser}
              candidateVotes={candidate.votes}
              name={candidate.name}
              image={candidate.image}
            />
          ))}
        </div>
        <Link className="centre" to='/election/executives'>Done with senate? Click to vote for executives</Link>
        <div className="centre">
        <button
          className="btn-submit draw-border"
          onClick={() => {
            VOTE(vote);
           
          
          }}
        >
          Submit
        </button>
      </div>
       
      </div>
  }
      </div>
    ) : (
      <div className="loading">
        <MyBackDrop />
      </div>
    );
  };
  
  const mapStateToProps = (state, ownProps) => ({
    position: SenateSelect(ownProps.match.params.level)(state),
    user: state.user,
  });
  
  const mapDispatchToProps = (dispatch) => ({
    VOTE: (vote) => dispatch(SENATE_VOTE(vote)),
  });
  export default connect(mapStateToProps, mapDispatchToProps)(SenateVotes);