import React, {useEffect} from 'react'
//import Chart from 'chart.js'
import {connect} from 'react-redux'
//import { Route } from 'react-router-dom';
import {createStructuredSelector} from 'reselect'
import {selectCurrentUser, } from '../../Redux/User/user.Selector'
import MyBackDrop from '../Modals/backDrop'
import { fetchUsersStartAsync} from '../../Redux/User/user.Actions'
//import MyAppBar from '../AppBar/AppBar'
import ChartsCard from '../Modals/EditCard'
//import VotingDirectory from '../votingDirectory/votingDirectory'
;


const Container = ({currentUser, match,}) => {
    const [open, setOpen] = React.useState(true);
    
    // useEffect(()=>{
    //     const ctx = document.getElementsByClassName('mychart')
//         const Piechart = new Chart(ctx,{
//             type:'doughnut',
//             data:  {
//                 datasets: [{
//                     data:levelsix? [levelsix.length, levelfive.length, levelfour.length,levelthree.length,leveltwo.length, levelone.length]:[1,1,1,1,1,1],
//                     backgroundColor:['#ff6384','#ffce56','blue', 'pink', 'brown', 'green']
//                 }],
            
//                 // These labels appear in the legend and in the tooltips when hovering different arcs
//                 labels: [
//                     '2K16',
//                     '2K17',
//                     '2K18',
//                     '2K19',
//                     '2K20',
//                     '2K21'
//                 ]
//             }
//         })
//     })
// console.log(levelsix)
    //currentUser? setOpen(false): setOpen(false)
    return  currentUser ?(

        <div className='profile-page'>
             
            <div className='onboard'>
                <p>Welcome, {currentUser.fullName}</p>
            </div>
            <div className='profile-container'>
        <div className='profile-details'>
    <img className='profile-img' src={currentUser.url ||'https://mpng.subpng.com/20180523/tha/kisspng-businessperson-computer-icons-avatar-clip-art-lattice-5b0508dc6a3a10.0013931115270566044351.jpg'} alt='profile-img'/>
        <h6>Matric No:{currentUser.matricNo}</h6>
        <hr/>
         <h6>Name:  {currentUser.fullName}</h6>
         <hr/>
         <h6>Email: {currentUser.email}</h6>
         <hr/>
         <h6>Level: {currentUser.level}</h6>
        </div>
        <div className='user-card'>
        <ChartsCard title='Pay Your Dues' content={ <canvas className='mychart'></canvas>}
          content = 'You can now pay your dues online!! '
        />
           
        </div>
             <div className='info-card'>
        <ChartsCard title='Voting Concluded' content='Voting concluded! The result will be released by the electoral committee soon!'/>

        </div>
        </div>
  
        <div className='btn-cont'>
       
        </div>
       
       
       

        <div>
            </div>
       
        </div>
    ) : (<div className='loading'>
        <MyBackDrop open={open} />
    </div>)
}


const mapStatetoProps = createStructuredSelector({
	currentUser:selectCurrentUser,
    //voteCount:selectVoteCount,
    // levelsix:select2k16 ,
    // levelfive:select2k17,
    // levelfour:select2k18,
    // levelthree:select2k19,
    // leveltwo:select2k20,
    // levelone:select2k21,
    // users:selectUsers,
    // loading: loading,
    
	
  })

  const mapdispatchToProps =(dispatch) => ({
    fetchUsers: ()=> dispatch(fetchUsersStartAsync())
  })


export default connect(mapStatetoProps, mapdispatchToProps)(Container)