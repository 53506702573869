import React from 'react'
import { FlutterWaveButton, closePaymentModal } from 'flutterwave-react-v3';
import {selectCurrentUser, loading } from '../../Redux/User/user.Selector'
import './payment.scss'
import { connect } from 'react-redux'
import {createStructuredSelector} from 'reselect'
import MyBackdrop from '../../components/Modals/backDrop';
import SignUp from '../../components/sign-in/sign-in'

export const Payments = ({user, loading}) => {
    const config = {
        public_key: 'FLWPUBK-87c4fbd80bf69d9ef8357de39c7f3c32-X',
        tx_ref: Date.now(),
        amount: 3000,
        currency: 'NGN',
        payment_options: 'card,mobilemoney,ussd',
        customer: {
          email: user?user.email:null,
          name: user? user.fullName:null,  
        },
        customizations: {
          title: 'UADS DUES',
          description: 'Pay Your Association Dues',
          logo: 'https://imgur.com/4iWbhEi.jpg',
        },
      };

  


    const fwConfig = {
        ...config,
        text: 'Pay Dues!',
        callback: (response) => {
           console.log(response);
          closePaymentModal() // this will close the modal programmatically
        },
        onClose: () => {},
      };  
    return loading? (<div className='loading'>
    <MyBackdrop />
    </div>)
    
    : user? (
        <div className='payment'>
            <h5 className='payment-title'>Pay Your Dues</h5>
            <div className='payment-logo'>
                <img src ='payment.svg' alt={"payment"} />
            </div>
            <FlutterWaveButton  className='btn btn-pay' {...fwConfig} />
        </div>
    ): <div>

      
      <SignUp />
      </div>
}

const mapStateToProps = createStructuredSelector({
	user:selectCurrentUser,
    loading:loading
	
  })


export default connect(mapStateToProps)(Payments)
