import React, {useState,  useEffect} from 'react';
import { auth } from '../../Firebase/Utility';
import {createStructuredSelector} from 'reselect'
import {loading, selectCurrentUser} from '../../Redux/User/user.Selector'
import Button from '@material-ui/core/Button';
import {connect} from 'react-redux'
import Avatar from '@material-ui/core/Avatar'
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container'; 
import ResetPassword from '../Modals/resetPassword'
import ResponseModal from '../Modals/responseModal'
import MyBackdrop from '../Modals/backDrop';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(15),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

 function SignIn({user, fetching, match}) {
  const classes = useStyles();
  
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [open, setOpen] = useState(false);
  const [responseOpen, setResponseOpen] = useState(false)
  const [modalEmail, setModalEmail] = useState('')
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [load, setLoad] = useState(true)
  const [error, setError] = useState('')
 
  const timer = React.useRef();
  

  useEffect(() => {
    
    return () => {
      clearTimeout(timer.current);
    };
  }, []);
  

  const handleClickOpen = () => {
    setOpen(true);
  }

 

  const handleResponse = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setSuccess(true);
        setLoading(false);
      }, 1500);
    }
  };
 
  const handleClose = () => {
      setOpen(false);
      auth.sendPasswordResetEmail(modalEmail).then(function() {
     setResponseOpen(true)
     handleResponse()
       
      }).catch(function(error) {
        // An error happened.
      });
  };
  const handleSubmit = async (event)=>{
    event.preventDefault();
    

    try {
        await auth.signInWithEmailAndPassword(email, password);
        setEmail('');
        setPassword('')
         
    } catch (error) {
      setError('Incorrect username or password')
    }
}

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form}  onSubmit={handleSubmit} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={email}
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(event) => {
              const {value } = event.target;
              setEmail(value); }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={password}
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(event) => {
              const {value } = event.target;
              setPassword(value); }}
          />
         <Grid>
            {error?<Box color='red' xs={12} sm={6}>
          {error}!!!
        </Box>:null}
            </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" onClick={handleClickOpen} variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item xs>
              <Link href="/signup" variant="body2">
               You don't have an account? Register here.
              </Link>
            </Grid>
            <Grid item>
             <ResetPassword 
             setModalEmail={setModalEmail}
             modalEmail={modalEmail}
              open={open} 
              handleClose={handleClose}/>
            </Grid>
            <Grid item>
             <ResponseModal
            responseOpen={responseOpen}
            loading={loading}
            success={success}
            setResponseOpen={setResponseOpen}/>
            </Grid>
              
          </Grid>
        </form>
      </div>
      
    </Container>
  );
}


const mapStatetoProps = createStructuredSelector({
	user:selectCurrentUser,
  fetching: loading
	
  })

export default connect(mapStatetoProps)(SignIn);