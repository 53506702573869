import {createSelector} from 'reselect'
const selectUser = (state) => state.user

export const selectCurrentUser = createSelector(
    [selectUser],
    (user) => user.currentUser
)

export const loading = createSelector([selectUser],
    (user) => user.isFetching
    )

    
export const selectUsers = createSelector(
    [selectUser],
    (user) => user.users
)


export const select2k16 = createSelector([selectUsers], 
    (users)=> (users?  users.filter(user=> user.level ==='2k16'):null)
        
    
)

export const select2k17 = createSelector([selectUsers], 
    (users)=> (users?  users.filter(user=> user.level ==='2k17'):null)
        
    
)
export const select2k18 = createSelector([selectUsers], 
    (users)=> (users?  users.filter(user=> user.level ==='2k18'):null)
        
    
)
export const select2k19 = createSelector([selectUsers], 
    (users)=> (users?  users.filter(user=> user.level ==='2k19'):null)
        
    
)
export const select2k20 = createSelector([selectUsers], 
    (users)=> (users?  users.filter(user=> user.level ==='2k20'):null)
        
    
)
export const select2k21 = createSelector([selectUsers], 
    (users)=> (users?  users.filter(user=> user.level ==='2k21'):null)
        
    
)