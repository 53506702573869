import React, {useState} from 'react';
import { auth, storage, CreateUserProfileDocument } from '../../Firebase/Utility';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import {createStructuredSelector} from 'reselect'
import {selectCurrentUser} from '../../Redux/User/user.Selector'
import {connect} from 'react-redux'
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Redirect } from 'react-router';




const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(12),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
 function SignUp({user}) {
  const classes = useStyles();

  const [email, setEmail] = useState('')
  const [fullName, setFullName] = useState('')
  const [matricNo, setMatricNo] = useState('')
  const [password, setPassword] = useState('')
  const [level, setLevel] = useState('')
  const [image, setImage] = useState()
  const [url, setUrl] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [disabled, setDisabled] =useState(false)
  const [error, setError] = useState('')
  
  
  const handleBtnClick=()=>{
    setDisabled(true)
  }

  const handleSubmit = async (event) =>{
    event.preventDefault()
    setDisabled(true)
      if(password!==confirmPassword){
          alert('password do not match')
      }
try {   
const uploadImage = storage.ref(`images/${matricNo}`).put(image)
const {user} = await auth.createUserWithEmailAndPassword(email, password)
await CreateUserProfileDocument(user, {fullName, url, matricNo, level})
    
// uploadImage.on(
//   'state_changed',
//   snapshot => {},
//   error => {
//     console.log(error)
//   },
//   ()=>{
//     storage
//     .ref('images')
//     .child(matricNo)
//     .getDownloadURL()
//     .then(url => {
//        CreateUserProfileDocument(user, {fullName, url, matricNo, level})
//     })
//   }
// )
   
      
      user.updateProfile({
        displayName:matricNo,

      })
      auth.signOut()
      await auth.signInWithEmailAndPassword(email, password)
      setEmail('')
      setFullName('')
      setPassword('')
      setLevel('')
      setConfirmPassword('')
      
} catch (error) {
    setError(error.message)
    setDisabled(false)
    
}
       
  }

  console.log(image)
  return user?.matricNo&&user?.uid?(<div> <Redirect  to='/login'/></div>):(
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit} validate='true'>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="name"
                name="fullName"
                variant="outlined"
                required
                fullWidth
                id="fullName"
                label="Full Name"
                autoFocus
                value ={fullName}
              onChange= {(event) => {
                const {value } = event.target;
                setFullName(value);   
            }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="matricNo"
                label="Matric Number"
                name="matricno"
                type='number'
                autoComplete="mNumber"
                value ={matricNo}
                onChange= {(event) => {
                  const {value } = event.target;
                  setMatricNo(Number(value))}}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <Button 
              variant='contained'
              component="label">
                 Upload Avatar
                 <input type='file'  
          onChange= {(event) => {
         if(event.target.files[0]){
           setImage(event.target.files[0])
         }
            
          }}
                 hidden />
              </Button>
            
            </Grid> */}
            <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
        <InputLabel id="demo-controlled-open-select-label">Level</InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          ariant="outlined"
                required
                fullWidth
                label="Level"
                name="level"
                autoComplete="level"
          
          value={level}
          onChange= {(event) => {
            const {value } = event.target;
            setLevel(value)
            
          }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {/* <MenuItem value={'2k18'}>2k18</MenuItem>
          <MenuItem value={'2k19'}>2k19</MenuItem> */}
          <MenuItem value={'2k20'}>2k20</MenuItem>
          <MenuItem value={'2k21'}>2k21</MenuItem>
          <MenuItem value={'2k22'}>2k22</MenuItem>
          <MenuItem value={'2k23'}>2k23</MenuItem>
          <MenuItem value={'2k24'}>2k24</MenuItem>
          <MenuItem value={'2k25'}>2k25</MenuItem>
          <MenuItem value={'2k26'}>2k26</MenuItem>
          <MenuItem value={'2k27'}>2k27</MenuItem>
        </Select>
      </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
            onChange={(event) => {
                const {value } = event.target;
                setEmail(value);   
            }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(event) => {
                  const {value } = event.target;
                  setPassword(value);   
              }} 
              value={password}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id='confirm-password'
                label='Confirm Password'
                type='password'
                autoComplete= 'Confirm Password'
                name='confirmPassword'
                value={confirmPassword}
                onChange={(event) => {
                    const {value } = event.target;
                    setConfirmPassword(value);   
                }}
              />
            </Grid>
            <Grid>
            {error?<Box color='red' xs={12} sm={6}>
          {error}!!!
        </Box>:null}
            </Grid>
           
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={disabled}
            omClick={handleBtnClick}
          >
            Sign Up
          </Button>
          <Grid container justify="center">
            <Grid item>
              <Link href="/login" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
     
    </Container>
  );
}

const mapStatetoProps = createStructuredSelector({
	user:selectCurrentUser,
	
  })

export default connect(mapStatetoProps)(SignUp);