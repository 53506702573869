import React from 'react'
import {connect} from 'react-redux'
import { Route } from 'react-router-dom';
import {createStructuredSelector} from 'reselect'
import MyBackDrop from '../Modals/backDrop'
import { fetchUsersStartAsync} from '../../Redux/User/user.Actions'
import './profile.scss'
import VotingDirectory from '../votingDirectory/votingDirectory'
import Votes from '../votes/votes'
import SenateHome from '../Senate/senateHome';
import SenateVotes from '../Senate/senateVotes';
import { selectCurrentUser } from '../../Redux/User/user.Selector';
import Container from '../Profilecontainer/Container';
import MyAppBar from '../AppBar/AppBar'


const Profile = ( {loading,match,}) => {
    const [open, setOpen] = React.useState(true);
  console.log(loading)
  
    return  loading?(

        <div className='profile-page'>
            
        <div>
            <MyAppBar />
            <Route exact path={`${match.path}`} component={Container} />
             {/* <Route exact path={`${match.path}/executives`} component={VotingDirectory}/>
             <Route path={`${match.path}/executives/:collectionId`}     render={(props)=>(<Votes {...props} />)} />  
             <Route exact path={`${match.path}/senate`}     component={SenateHome} />  
             <Route path={`${match.path}/senate/:level`}     render={(props)=>(<SenateVotes {...props} />)} />  
              */}
        </div>
       
        </div>
    ) : (<div className='loading'>
        <MyBackDrop open={open} />
    </div>)
}


const mapStatetoProps = createStructuredSelector({
	loading:selectCurrentUser
	
  })

  // const mapdispatchToProps =(dispatch) => ({
  //   fetchUsers: ()=> dispatch(fetchUsersStartAsync())
  // })


export default connect(mapStatetoProps,)(Profile)
