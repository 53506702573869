import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ResetPassword({open, modalEmail, setModalEmail, handleClose,}) {
  

  

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Reset Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
           Please enter your email address
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="modalEmail"
            name="modalEmail"
            label="Email Address"
            type="email"
            value={modalEmail}
            fullWidth
            onChange={(event) => {
                const {value } = event.target;
                setModalEmail(value); }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Reset Password
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
