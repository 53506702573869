 import PositionTypes from "./position.types";
 import {firestore} from '../../Firebase/Utility'
import { convertPositionsSnapShotToMap} from '../../Firebase/Utility'

export const ADD_VOTE =item=> ({
    type:PositionTypes.ADD_VOTE,
    payload:item
})

export const SENATE_VOTE =item=> ({
    type:PositionTypes.SENATE_VOTE,
    payload:item
})


export const fetchPositionStart = ()=>({
    type:PositionTypes.FETCH_POSITION_START
})

export const fetchPositionSuccess = (collections) => ({
    type:PositionTypes.FETCH_POSITION_SUCCESS,
    payload:collections
})
export const fetchPositionFailure = (error) => ({
    type:PositionTypes.FETCH_POSITION_FAILURE,
    payload:error
})

export const fetchPositionsStartAsync = ()=>  {
    try {
        return dispatch => {
            const userRef = firestore.collection('positions')
            dispatch(fetchPositionStart())
            userRef.onSnapshot(snapshot =>{
               const collections = convertPositionsSnapShotToMap(snapshot);
             dispatch(fetchPositionSuccess(collections))  
            })
   
     }
    } catch (error) {
        //dispatch(fetchPositionFailure(error.Message))
    }
   
}