import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { connect } from 'react-redux';
import {createStructuredSelector} from 'reselect'
import { selectExco, selectClass, selectLegis} from '../../Redux/Positions/position.selector';
import Paper from '@material-ui/core/Paper';
import MyAppBar from '../../components/AppBar/AppBar'
import { selectUsers } from '../../Redux/User/user.Selector';
import { Divider } from '@material-ui/core';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(name, position, yes, no) {
  return { name, position, yes, no};
}

function calculateYesVote(voteArray){
    let Yes = 0;

    
   if(voteArray){
    voteArray.map(vote=>{
        if(vote.vote=='Yes'  && vote!==null){
          Yes++  
        }
        
    })
    
   } 
   return Yes
}

function calculateNoVote(voteArray){
    let No= 0;
   if (voteArray){
    voteArray.map(vote=>{
        if(vote.vote=='No' && vote!==null){
          No++  
        }
       
    })
   } 
   return No
}


const useStyles = makeStyles (theme=>({
  container:{
  width:'50%',
  marginLeft:'25%',
  marginBottom:'2%',
  [theme.breakpoints.down('sm')]: {
    width: '90%',
    marginLeft:'5%',
   
  },

 
  },
  table: {
    maxWidth: '100%',
    
    
  headText:{
      marginTop:'2rem',
       //marginBottom:'2rem'
},
  }
}));
      
 function Result({position, users, classList, senate}) {
  const classes = useStyles();
  let rows;
  console.log(classList)
 console.log(users)
  const getVoters = (classList, users) =>{
    
   let myusers =   classList.map(candidate=>{
    
    let levelone = {yes:0,no:0};
    let leveltwo = {yes:0,no:0};
    let levelthree = {yes:0,no:0};
    let levelfour = {yes:0,no:0};
    let levelfive = {yes:0,no:0};
    let levelsix = {yes:0,no:0};

     candidate.voters.map(vote=>{
         let user = users[vote.matricNo]
        if (user.level=='2k21'&& vote.vote==='Yes'){
          levelone.yes++;
        }else if(user.level==='2k21'&&vote.vote==='No'){
          levelone.no++;
        }
        if (user.level=='2k20' && vote.vote==='Yes'){
          leveltwo.yes++;
        }else if(user.level==='2k20'&&vote.vote==='No'){
          leveltwo.no++;
        }
        if (user.level=='2k19'&& vote.vote==='Yes'){
          levelthree.yes++;
        }else if(user.level==='2k19'&&vote.vote==='No'){
          levelthree.no++;
        }
        if (user.level=='2k18'&& vote.vote==='Yes'){
          levelfour.yes++;
        }else if(user.level==='2k18'&&vote.vote==='No'){
          levelfour.no++;
        }
        if (user.level=='2k17'&& vote.vote==='Yes'){
          levelfive.yes++;
        }else if(user.level==='2k17'&&vote.vote==='No'){
          levelfive.no++;
        }
        if (user.level=='2k16'&& vote.vote==='Yes'){
          levelsix.yes++;
        }else if(user.level==='2k16'&&vote.vote==='No'){
          levelsix.no++;
        }
       }) 

       return {
         name:candidate.name,
         levelone,
         leveltwo,
         levelthree,
         levelfour,
         levelfive,
         levelsix
       }
     })

     return myusers
  }

  const myList = users? getVoters(classList, users): null

console.log(myList)

  rows = position? position.map(candidate=> createData(candidate.name, candidate.position, calculateYesVote(candidate.votes), calculateNoVote(candidate.votes))): [];
console.log(rows)
  return (
      <div>     
<MyAppBar />
 
          <p className='result-title'>Results for Executives</p>  

          <TableContainer className={classes.container} component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Candidate</StyledTableCell>
            <StyledTableCell align="left">Position
            </StyledTableCell>
            <StyledTableCell align="left">Yes</StyledTableCell>
            <StyledTableCell align="left">No</StyledTableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="left">{row.position}</StyledTableCell>
              <StyledTableCell align="left">{row.yes}</StyledTableCell>
              <StyledTableCell align="left">{row.no}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer >
{senate.map(level=>{
  return  <div>
  <p className='result-title'>Result For {level.class}</p>
    <TableContainer className={classes.container} component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Candidate</StyledTableCell>
            <StyledTableCell align="right">Yes</StyledTableCell>
            <StyledTableCell align="right">No</StyledTableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
          {level.candidates.map((row) => {
            
            return (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              
              <StyledTableCell align="right">{calculateYesVote(row.votes)}</StyledTableCell>
              <StyledTableCell align="right">{calculateNoVote(row.votes)}</StyledTableCell>
            </StyledTableRow>
          )})}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
})}  </div>
    
  );
}

const mapStateToProps = createStructuredSelector({
    position: selectExco,
    senate: selectLegis,
    classList:selectClass,
    users: selectUsers
    
  });

  export default connect(mapStateToProps)(Result);