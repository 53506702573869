import React from 'react';
import Team from '../../components/Team/Team';

const teamArray = [
	{
		name: 'Fiyin Atinmo',
		img: 'https://firebasestorage.googleapis.com/v0/b/uads-2b6ac.appspot.com/o/resources%2Fimages%2FFiyin_Atinmo_34.jpg?alt=media&token=ae8e5769-f5f8-4498-852b-4bc4344605b2',
		position: 'President',
		twitter: 'https://twitter.com/theFiyinAtinmo',
		instagram: "https://instagram.com/fiyinatinmo"
	},
	{
		name: 'Arokoyo Kehinde',
		img: '/teams/arokoyo.jpg',
		position: 'Vice President'
	},	
	{
		name: 'Alarape Zaynab',
		img: 'https://firebasestorage.googleapis.com/v0/b/uads-2b6ac.appspot.com/o/resources%2Fimages%2FAlarape_Zainab.jpg?alt=media&token=d665ebf6-3cf5-4373-bfd8-82565525fca8',
		position: 'CODEH Chairperson',
		twitter: 'https://twitter.com/zaynabalarape_',
		instagram: 'https://twitter.com/zaynabalarape_'
	},	
	{
		name: 'Eyitanwa Eunice',
		img: 'https://firebasestorage.googleapis.com/v0/b/uads-2b6ac.appspot.com/o/resources%2Fimages%2Ftanwa.jpg?alt=media&token=3dc69bed-4b40-4390-88b4-09fa3ca506a5',
		position: 'General Secretary',
		twitter: 'https://twitter.com/neeceyou',
		instagram: 'https://twitter.com/euniceeyitanwa',
	},
	{
		name: 'Adelola Ayomide Abel',
		img: '/teams/abel.jpg',
		position: 'Assistant General Secretary',
		twitter: 'https://twitter.com/AyomideAdelola',
		instagram: 'https://twitter.com/dr_abelium',
	},
	{
		name: 'Salimon Abdulrazaq Adebayo',
		img: '/teams/razaq.jpg',
		position: 'Treasurer',
		twitter: 'https://twitter.com/Razaq_Salimon',
		instagram: 'https://twitter.com/razaq_salimon',
	},
	{
		name: 'Okelue Amaechi Patrick',
		img: '/teams/patrick.jpg',
		position: 'Public Relations Officer',
		twitter: 'https://twitter.com/IAmPatrickOA',
		instagram: 'https://twitter.com/iampatrickoa',
	},
	{
		name: 'Bakare Abdulqodir Abidemi',
		img: '/teams/quadri.jpg',
		position: 'Sports Secretary',
		twitter: 'https://twitter.com/baqreQod22',
		instagram: 'https://twitter.com/bakareabdulqodir',
	},
	{
		name: 'Adebayo Opeoluwa',
		img: '/teams/maxwell.jpg',
		position: 'Special Duties Officer'
	},

];
const TeamPage = () => {
	return (
		<div class="team">
			<div class="container">
				<div class="section-header">
			
					<h2>The Executive Council</h2>
				</div>
				<div class="row">
					{teamArray.map((mem) => (
						<Team
							name={mem.name}
                            twitter={mem.twitter}
							position={mem.position}
                            img={mem.img} 
							instagram={mem.instagram}
						/>
					))}
				</div>
			</div>
		</div>
	);
};


export default TeamPage