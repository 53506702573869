import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {auth} from '../../Firebase/Utility'
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import {Link} from 'react-router-dom'
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';



const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }));

export default function AppMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const logout =  async ()=>{
    
    await auth.signOut()
    
}
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton  onClick={handleClick} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon/>
          </IconButton>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleClose}><Link to='/login'>Profile</Link></MenuItem>
        <MenuItem onClick={handleClose}><Link to='/about'>About</Link></MenuItem>
        <MenuItem onClick={handleClose}><Link to='/payments'>Pay Dues</Link></MenuItem>
        <MenuItem onClick={handleClose}><Link to='/contact'>Contact Us</Link></MenuItem>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </div>
  );
}
