import React, {useState} from 'react'
import { connect } from 'react-redux'
import { loading, selectCurrentUser, selectUsers } from '../../Redux/User/user.Selector'
import {createStructuredSelector} from 'reselect'
import ReactPaginate from 'react-paginate';

const Memberlist = ({users,currentUser, loading}) => {
const [ currentPage, setCurrentPage ] = useState(0);

    const PER_PAGE = 20;
    const offset = currentPage * PER_PAGE;
    const pageCount = users? Math.ceil(users.length / PER_PAGE): 1;
    const currentPageData = users? users.slice(offset, offset + PER_PAGE).map(user=>{
return <div><span className='user-name'>{user.fullName}</span>: <span className='user-matricNo'>{user.matricNo} {user.level}</span></div>
    }): null
console.log(currentUser)
    function handlePageClick({ selected: selectedPage }) {
		setCurrentPage(selectedPage);
	}

    return !users? (<div>loading...</div>):(
        <div className='page-list'>
<ReactPaginate
				previousLabel={'← Previous'}
				nextLabel={'Next →'}
				pageCount={pageCount}
				onPageChange={handlePageClick}
				containerClassName={'pagination'}
				previousLinkClassName={'pagination__link'}
				nextLinkClassName={'pagination__link'}
				disabledClassName={'pagination__link--disabled'}
				activeClassName={'pagination__link--active'}
			/>

<div>{currentPageData}</div>

        </div>
        
    )
}

const mapStatetoProps = createStructuredSelector({
	currentUser:selectCurrentUser,
	users:selectUsers
  })

   

export default connect(mapStatetoProps)(Memberlist)
