import { firestore } from '../../Firebase/Utility';
import firebase from 'firebase/app';

export const ADD_VOTE = (obj) => {
	const { votes, name, position } = obj;
console.log(position)
	const CandidateRef = firestore.collection('positions').doc('executive').update({
	[`${position}.${name}.votes`]:firebase.firestore.FieldValue.arrayUnion({...votes})
}, {merge:true});

	return null;
};


export const SENATE_VOTE = (obj) => {
	const { votes, name, position } = obj;
console.log(position)
	const CandidateRef = firestore.collection('positions').doc('legislative').update({
	[`${position}.${name}.votes`]:firebase.firestore.FieldValue.arrayUnion({...votes})
}, {merge:true});

	return null;
};